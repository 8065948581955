<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap justify-content-start">
        <div class="d-flex align-items-center">
          <TMessage content="Payment refund" bold />
          <TSpinner :loading="creating" />
        </div>
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            color="primary"
            class="mr-1"
            @click="clearInput"
          />
          <TButton
            content="Create"
            icon="cis-plus"
            @click="create"
            :options="{ disabled: creating }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="7">
            <CRow class="row-gap-3">
              <CCol md="6">
                <div
                  style="position: relative"
                  @click="showModalSelectOrder = true"
                >
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  />
                  <TInputText
                    label="Order"
                    :value="temp.order && temp.order.id"
                    placeholder="Select order"
                  />
                </div>
              </CCol>

              <CCol md="6">
                <TInputText
                  label="Customer"
                  :value="temp.order && temp.order.customer.name"
                  disabled
                />
              </CCol>
            </CRow>

            <CRow class="row-gap-3 mt-3">
              <CCol md="3">
                <TInputDateTime
                  label="Voucher date"
                  :value.sync="input.voucher_date"
                  :option="{ disabled: true }"
                />
              </CCol>

              <CCol md="3">
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>

              <CCol md="6">
                <TInputMoney
                  label="Amount"
                  :value.sync="input.amount"
                  :currency="input.currency_id"
                />
              </CCol>

              <CCol md="12">
                <TInputTextarea
                  label="Description"
                  placeholder="Description"
                  :value.sync="input.description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="5">
            <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />

            <div class="p-3 bg-gray-100 rounded mt-3" v-if="temp.order">
              <TMessage content="Fees can be consulted" />

              <hr />

              <CRow class="row-gap-3">
                <CCol md="6">
                  <TInputMoney
                    label="Purchase cost"
                    :value="temp.order.purchase_cost"
                    :currency="temp.order.purchase_cost_currency_id"
                    disabled
                  />
                </CCol>

                <CCol md="6">
                  <TInputMoney
                    label="Service charge"
                    :value="temp.order.service_fee"
                    :currency="temp.order.service_fee_currency_id"
                    disabled
                  />
                </CCol>

                <CCol md="4">
                  <TInputMoney
                    label="Domestic delivery fee"
                    :value="temp.order.cod_cost"
                    currency="JPY"
                    disabled
                  />
                </CCol>

                <CCol md="4">
                  <TInputMoney
                    label="Additional cost"
                    :value="temp.order.additional_cost"
                    currency="JPY"
                    disabled
                  />
                </CCol>

                <CCol md="4">
                  <TInputMoney
                    label="Commodity compensation"
                    :value="temp.order.compensation"
                    currency="VND"
                    disabled
                  />
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <SModalSelectOrder
      :show.sync="showModalSelectOrder"
      @select="onChangeOrder"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      temp: {},
      input: {},
      receipts: [],
      showModalSelectOrder: false,
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_payment_order_refund.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_payment_order_refund.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "user",
        "receipts[0][receiptable_id]": this.temp?.order?.id,
        "receipts[0][receiptable_type]": "order",
      };

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index + 1}][receiptable_type]`] = "file";
        params[`receipts[${index + 1}][file]`] = receipt.file;
      });

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    onChangeOrder(order) {
      this.$set(this.temp, "order", order);
      this.$set(this.input, "transactionable_id", order.customer_id);
      this.$set(this.input, "currency_id", order.purchase_cost_currency_id);
      this.$set(
        this.input,
        "amount",
        Math.abs(order.purchase_cost_outstanding)
      );
    },
  },
};
</script>
