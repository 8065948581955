<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #payment-order>
        <PaymentOrder />
      </template>
      <template #payment-service>
        <PaymentService />
      </template>
      <template #discount-sales>
        <DiscountSales />
      </template>
      <template #payment-lading-bill>
        <PaymentLadingBill />
      </template>
      <template #payment-credit-order>
        <PaymentCreditOrder />
      </template>
    </TTabs>
  </div>
</template>

<script>
import PaymentOrder from "./PaymentOrder.vue";
import PaymentCreditOrder from "./PaymentCreditOrder.vue";
import DiscountSales from "./DiscountSales.vue";
import PaymentService from "./PaymentService.vue";
import PaymentLadingBill from "./PaymentLadingBill.vue";

export default {
  components: {
    PaymentOrder,
    DiscountSales,
    PaymentService,
    PaymentLadingBill,
    PaymentCreditOrder,
  },
  data() {
    return {
      tabs: [
        {
          key: "payment-order",
          name: "Payment for order",
          icon: "cisLibraryBooks",
        },
        {
          key: "payment-credit-order",
          name: "Payment for order credit",
          icon: "cisLibraryBooks",
        },
        {
          key: "payment-service",
          name: "Service fee payment",
          icon: "cisLibraryBooks",
        },
        {
          key: "payment-lading-bill",
          name: "Bill of lading payment",
          icon: "cisLibraryBooks",
        },
        {
          key: "discount-sales",
          name: "Payment refund",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
