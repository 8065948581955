<template>
  <TModal
    title="Select lading bill"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div></div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.lading_bills_receivable"
      resource=""
      @click-clear-filter="clearFilter"
    >
      <template #_="{ item }">
        <td>
          <TButtonEnter @click="select(item)" />
        </td>
      </template>
      <template #customer="{ item }">
        <td class="text-break">
          <TMessage
            v-if="item.customer"
            :content="item.customer.name"
            noTranslate
            class="text-nowrap"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #id="{ item }">
        <td>
          <TLink
            :to="`${lodash.getReferenceLink('lading_bill', item.container_id)}&tab=1`"
            :content="item.name || item.id.toString()"
          />
        </td>
      </template>
      <template #contract_id="{ item }">
        <td>
          <TLink
            v-if="item.contract_id"
            :to="lodash.getReferenceLink('contract', item.contract_id)"
            :content="item.contract_id"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #container_id="{ item }">
        <td>
          <TLink
            v-if="item.container_id"
            :to="`${lodash.getReferenceLink('invoice', item.container_id)}&tab=1`"
            :content="item.container_id.toString()"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #freight_charge="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.freight_charge_paid"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.freight_charge"
              :currency="item.currency_id"
            />
          </div>
        </td>
      </template>

      <template #freight_charge_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_outstanding"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #freight_charge_debited="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_debited"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
      <template #customer-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
      <template #container_id-filter>
        <TInputText
          :value.sync="filter.container_id"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
      <template #contract_id-filter>
        <TInputText
          :value.sync="filter.contract_id"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      list: "warehouse.lading_bills_receivable.list",
    }),
    fields() {
      return [
        { key: "_", _style: "width: 40px" },
        {
          key: "customer",
          label: "Customer",
          _classes: "",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "id",
          label: "Lading bill",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "container_id",
          label: "Invoice",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "contract_id",
          label: "Contract",
          _classes: "text-nowrap",
          _style: "width: 170px; min-width: 170px",
        },
        { key: "freight_charge", label: "Paid/Shipping fee" },
        {
          key: "freight_charge_debited",
          label: "Debited",
          sorter: true,
        },
        {
          key: "freight_charge_outstanding",
          label: "Outstanding",
          sorter: true,
        },
      ];
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    select(lb) {
      this.$emit("select", lb);
      this.$emit("update:show", false);
      this.key = this.lodash.resetKey("btn-select");
    },
    filterChange() {
      let defaultFilter = {};
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch(
        "warehouse.lading_bills_receivable.apply-query",
        query
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
