<template>
  <TModal
    title="Select order credit"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div></div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="accounting.credit_orders"
      resource=""
      @click-clear-filter="clearFilter"
    >
      <template #_="{ item }">
        <td class="text-center">
          <TButton
            icon="cil-check"
            @click="select(item)"
            variant="ghost"
            size="sm"
          />
        </td>
      </template>
      <template #order_id="{ item }">
        <td>
          <TLink
            v-if="item.transactions.length"
            :to="
              lodash.getReferenceLink(
                'order',
                item.transactions[0].receipts[0].receiptable_id
              )
            "
            :content="item.transactions[0].receipts[0].receiptable_id"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="getCustomer(item)" noTranslate />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #outstanding_amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.outstanding_amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #paid_amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.paid_amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #payment_due_date="{ item }">
        <td>
          <TMessageDateTime :content="item.payment_due_date" dateOnly />
        </td>
      </template>
      <template #payment_due_date-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['payment_due_date']"
          @update:value="filterChange"
        />
      </template>
      <template #order_id-filter>
        <TInputText
          :value.sync="filter.by_order"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
      <template #customer-filter>
        <SSelectCustomer
          :value.sync="filter['wallet.walletable_id']"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
      filter_between: {},
    };
  },
  computed: {
    ...mapGetters({
      list: "accounting.credit_orders.list",
    }),
    fields() {
      return [
        { key: "_", _style: "width: 40px" },
        {
          key: "order_id",
          label: "Order Id",
          _classes: "",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "customer",
          label: "Customer",
          _classes: "",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
          sorter: true,
        },
        {
          key: "amount",
          label: "Amount",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "outstanding_amount",
          label: "Outstanding",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "paid_amount",
          label: "Paid",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
      ];
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    select(lb) {
      this.$emit("select", lb);
      this.$emit("update:show", false);
      this.key = this.lodash.resetKey("btn-select");
    },
    filterChange() {
      let defaultFilter = {};
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      let paymentDueDateFilter = "";
      if (this.filter_between.payment_due_date)
        paymentDueDateFilter = `${this.filter_between?.payment_due_date?.start},${this.filter_between?.payment_due_date?.end}`;
      const query = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
        "filter_between[payment_due_date]": paymentDueDateFilter,
      });
      this.$store.dispatch("accounting.credit_orders.apply-query", query);
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
    getCustomer(item) {
      return item.wallet?.object
        ? item.wallet?.object.name
        : item.wallet?.walletable_id;
    },
  },
};
</script>
