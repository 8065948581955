<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap justify-content-start">
        <div class="d-flex align-items-center">
          <TMessage content="Payment for order credit" bold />
          <TSpinner :loading="creating" />
        </div>
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            color="primary"
            class="mr-1"
            @click="clearInput"
          />
          <TButton
            content="Create"
            icon="cis-plus"
            @click="create"
            :options="{ disabled: creating }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="7">
            <CRow class="row-gap-3">
              <CCol md="6">
                <div
                  style="position: relative"
                  @click="showModalSelectOrder = true"
                >
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  />
                  <TInputText
                    label="Order credit"
                    :value="temp.credit_order && temp.credit_order.id"
                    placeholder="Select"
                  />
                </div>
              </CCol>

              <CCol md="6">
                <TInputText
                  label="Customer"
                  :value="temp.customer && temp.customer.name"
                  disabled
                />
              </CCol>
            </CRow>

            <CRow class="row-gap-3 mt-3">
              <CCol md="3">
                <TInputDateTime
                  label="Voucher date"
                  :value.sync="input.voucher_date"
                  :option="{ disabled: true }"
                />
              </CCol>

              <CCol md="3">
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>

              <CCol md="6">
                <TInputMoney
                  label="Amount"
                  :value.sync="input.amount"
                  :currency="input.currency_id"
                />
              </CCol>

              <CCol md="12">
                <TInputTextarea
                  label="Description"
                  placeholder="Description"
                  :value.sync="input.description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="5">
            <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <modal-select-credit-order
      :show.sync="showModalSelectOrder"
      @select="onChangeOrder"
    />
    <!-- <SModalSelectOrder

    /> -->
  </div>
</template>

<script>
import ModalSelectCreditOrder from "../../../components/ModalSelectCreditOrder.vue";
export default {
  components: { ModalSelectCreditOrder },
  data() {
    return {
      temp: {},
      input: {},
      receipts: [],
      showModalSelectOrder: false,
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_payment_order.loading"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_payment_order.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "user",
        "receipts[0][receiptable_id]": this.temp?.credit_order?.id,
        "receipts[0][receiptable_type]": "credit_order",
      };

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index + 1}][receiptable_type]`] = "file";
        params[`receipts[${index + 1}][file]`] = receipt.file;
      });

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    onChangeOrder(credit_order) {
      this.$set(this.temp, "credit_order", credit_order);
      this.$set(
        this.temp,
        "customer",
        credit_order?.wallet?.object
          ? credit_order?.wallet?.object
          : { name: credit_order?.wallet?.walletable_id }
      );
      this.$set(
        this.input,
        "transactionable_id",
        credit_order.wallet.walletable_id
      );
      this.$set(this.input, "currency_id", credit_order.wallet.currency_id);
      this.$set(this.input, "amount", credit_order.outstanding_amount);
    },
  },
};
</script>
